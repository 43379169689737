import React from "react"
import css from "@emotion/css"
import Container from "./container"

export function HeaderAlternative({ children }) {
  return (
    <header
      css={css`
        position: relative;
        overflow: visible;
        padding: 100px 0;
        background-image: linear-gradient(-180deg, #3ecf8e 0%, #72e9c1 100%);
        text-align: center;
      `}
    >
      <Container
        css={css`
          max-width: 100%;
          width: 620px;
          flex-direction: column;
        `}
      >
        {children}
      </Container>
    </header>
  )
}
