import React from "react"
import css from "@emotion/css"

export const Box = ({ children }) => (
  <div
    css={css`
      max-width: 100%;
      width: 620px;
      padding: 25px 20px;
      background: #fff;
      box-shadow: 0 50px 100px -20px rgba(50, 93, 72, 0.4);
      border-radius: 4px;
      z-index: 1;
    `}
  >
    {children}
  </div>
)
