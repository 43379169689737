import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeaderAlternative } from "../components/header-alternative"
import { HeaderTitle, HeaderSubtitle } from "../components/header"
import Container from "../components/container"
import { Box } from "../components/box"
import css from "@emotion/css"
import ContactSuccessImage from "../images/contact-success.svg"

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contact us" />

      <HeaderAlternative>
        <HeaderTitle>Contact our team</HeaderTitle>
        <HeaderSubtitle>
          We are happy to answer all your questions. Fill out form and we'll be
          in touch soon.
        </HeaderSubtitle>
      </HeaderAlternative>

      <Container
        css={css`
          margin-top: -50px;
          justify-content: center;
        `}
      >
        <Box
          css={css`
            height: 420px;
          `}
        >
          <h2
            css={css`
              max-width: 420px;
              margin: 0 auto;
              margin-bottom: 30px;
              text-align: center;
              line-height: 1.4;
            `}
          >
            Thank you for contacting us. We will get back to you soon
          </h2>
          <img
            css={css`
              margin-bottom: -30px;
            `}
            src={ContactSuccessImage}
            className="img-responsive"
            alt="Success illustration"
          />
        </Box>
      </Container>
    </Layout>
  )
}

export default ContactPage
